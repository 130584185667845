import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import IframeResizer from 'iframe-resizer-react';

import { dateCurrent, calcDate } from '../../utilities';
import theme from '../../theme';

export default function DashboardScreen() {
  console.log("dash screen")
  const SaleChannel = useSelector(state => state.Main.sale_channel);
  const [IsLoading, setIsLoading] = useState(true);
  const [Url, setUrl] = useState();
  let { index } = useParams();

  const dateFilter = '?data_inicial=' + calcDate("en-US", 30) + '&data_final=' + dateCurrent("en-US");

  useEffect(() => {
    if (index && SaleChannel.Dashboards[index]) {
      setUrl(SaleChannel.Dashboards[index].Url);
      setIsLoading(false);
    }
  }, [index, SaleChannel.Dashboards]);

  if (!IsLoading) {
    if (index !== 0) {
      return (
        <iframe title="iframe" src={Url + dateFilter} height="1200" style={{ border: 0 }} />
      )
    } else {
      return (
        <IframeResizer
          heightCalculationMethod="lowestElement"
          inPageLinks
          src={Url + dateFilter}
          style={{ minWidth: '100%', border: 0 }}
        />
      )
    }
  } else {
    return (
      <div style={{ fontWeight: 'bold', fontSize: 19, color: theme.palette.primary.main }}>
        Página não encontrada!
      </div>
    )
  }
}