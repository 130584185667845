import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import PrivateRoutes from '../../routes/private-routes';
import SideMenu from './side-menu';

import useStyles from './style';

function Main(props) {
  const [open, setOpen] = useState(true);

  const SaleChannel = useSelector(state => state.Main.sale_channel);

  const styles = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(styles.appBar, open && styles.appBarShift)}>
        <Toolbar className={styles.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={clsx(styles.title, !open && styles.menuButtonVisible)}>
            <b>{SaleChannel.Name}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(styles.drawerPaper, !open && styles.drawerPaperClose),
        }}
        open={open}
      >
        <div className={styles.toolbarIcon}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(styles.menuButton, open && styles.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={handleDrawerClose}
            className={clsx(!open && styles.menuButtonHidden)}
          >
            <ChevronLeftIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
        <Divider />
        <List style={{ height: '100%' }}>
          <SideMenu {...props} MenuStatus={open} />
        </List>
      </Drawer>
      <main className={styles.content}>
        <div className={styles.appBarSpacer} />
        <Container maxWidth={false} className={styles.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={styles.paper}>
                <PrivateRoutes />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default withRouter(Main)