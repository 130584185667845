import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8353bc'
    },
    secondary: {
      main: '#000',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#eef5f9',
    },
    sideMenu: {
      color: '#fff'
    }
  },
  overrides: {
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#eee',
          color: '#000'
        }
      }
    },
  },
});

export default theme;