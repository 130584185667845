import api from '../../services/api';
import config from '../../config';
import store from '../../store';
import type from  '../../store/actions/types';


const setSaleChannel = (data) => ({
  type: type.SET_SALE_CHANNEL,
  payload: data
});

export const verifyLogged = () => {
  const data = {
    CdUserAccount: localStorage.getItem('CdUserAccount'),
    Email: localStorage.getItem('Email'),
    IdSaleChannel: localStorage.getItem('IdSaleChannel')
  }

  const SelectSaleChannel = config.companies.findIndex(element => element.IdSaleChannel === parseInt(data.IdSaleChannel));

  if(Boolean(data.CdUserAccount) && Boolean(data.Email) && Boolean(data.IdSaleChannel) && SelectSaleChannel !== -1) {
    store.dispatch(setSaleChannel(config.companies[SelectSaleChannel]));
    return true;
  } else {
    return false;
  }
}

export const doLogin = async (params) => {
  try {
    const { data } = await api.post('/UserAccount/Login', params);

    const SelectSaleChannel = config.companies.findIndex(element => element.IdSaleChannel === data.IdSaleChannel);
    
    if(data && SelectSaleChannel !== -1) {
      localStorage.setItem('CdUserAccount', data.CdUserAccount);
      localStorage.setItem('Email', data.Email);
      localStorage.setItem('IdSaleChannel', data.IdSaleChannel);
      return true;
    } else {
      return false;
    }
  }
  catch (err) {
    console.log(err);
    return false;
  }
}

export const Logout = () => {
  try {
    if (verifyLogged()) {
      localStorage.clear();
    }
  } catch (err) {
    console.log(err);
  }
}