import { createStore, combineReducers } from 'redux';

import MainReducer from './reducers/main'

const rootReducer = combineReducers({
    Main: MainReducer,
});

const store = createStore(rootReducer)

export default store;