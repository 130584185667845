import type from '../actions/types'

const INITIAL_STATE = {
  sale_channel: {},
}

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case type.SET_SALE_CHANNEL:
      return {
        ...state,
        sale_channel: action.payload
      };
    default:
      return state;
  }
}

export default reducer;