import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

import { doLogin } from '../../store/actions';

import logo from '../../assets/images/logo.png';

import useStyles from './style'

export default function LoginScreen() {
  console.log("login screen")
  const [errorLogon, setErrorLogon] = useState(false);
  const { handleSubmit, control } = useForm();
  const styles = useStyles();

  const onSubmit = async data => {
    const res = await doLogin(data);
    if (res) {
      setErrorLogon(false);
      window.location.href = '/'
    } else {
      setErrorLogon(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={styles.paper}>
        <Typography component="h1" variant="h5" style={{ marginBottom: 30 }}>
          <img alt="logo" src={logo} style={{ maxWidth: 300 }} />
        </Typography>
        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={TextValidator}
            name="Email"
            control={control}
            defaultValue=""
            className={styles.input}
            label="E-mail"
            fullWidth
            autoFocus
            variant="outlined"
            margin="normal"
            validators={['required','isEmail']}
            errorMessages={['Campo obrigatório','Email inválido']}
          />
          <Controller
            as={TextValidator}
            name="CdUserAccount"
            control={control}
            defaultValue=""
            className={styles.input}
            label="Senha"
            fullWidth
            type="password"
            variant="outlined"
            margin="normal"
            validators={['required']}
            errorMessages={['Campo obrigatório']}
          />
          {errorLogon && <Alert severity="error">Email ou senha inválidos!</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submit}
          >
            ENTRAR
          </Button>
        </ValidatorForm>
      </div>
    </Container>
  );
}