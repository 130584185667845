export const dateCurrent = (format, date = null) => {
  let d = new Date();
  if(date) {
    d = new Date(date);
  }
  let month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();

  if(month <= 9) {
    month = "0" + month;
  }

  let dateNow = null;

  if(format === "pt-BR") {
    dateNow = day.toString() + "/" + month.toString() + "/" + year.toString();
  }
  if(format === "en-US") {
    dateNow = year.toString() + "-" + month.toString() + "-" + day.toString();
  }

  return dateNow;
}

export const calcDate = (format, day) => {
  const currentDate = new Date();
  const dateTimestamp = currentDate.setDate(currentDate.getDate() - day);

  return dateCurrent(format, dateTimestamp);
}