const dev = {
  pathApi: '/api'
};

const prod = {
  pathApi: 'https://prd-cp-005-api-security.azurewebsites.net/api'
};

const env = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...env
};