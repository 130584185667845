import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DashboardScreen from '../container/dashboard';

function PrivateRoutes() {
const publicUrl = process.env.PUBLIC_URL;

  return (
    <Switch>
      <Route path={`${publicUrl}/dashboard/:index`} exact component={DashboardScreen} />
      <Redirect to={{ pathname: `${publicUrl}/dashboard/0` }}/>
    </Switch>
  );
}

export default PrivateRoutes;