import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dashboard from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import theme from '../../theme';
import { Logout } from '../../store/actions';
import useStyles from './style';

import logo from '../../assets/images/logo.png'
import logoSmall from '../../assets/images/logo36x36.png'

export default function SideMenuSideMenu(props) {
  const SaleChannel = useSelector(state => state.Main.sale_channel);
  
  const { MenuStatus } = props;
  const pathCurrent = props.location.pathname;
  const styles = useStyles();

  function doLogout() {
    Logout();
    window.location.href = '/login'
  }

  return (
    <div style={{ height: '320px' }}>
      <div style={{ textAlign: 'center', marginBottom: 20 }}>
        <img alt="logo" src={MenuStatus ? logo : logoSmall} style={{ maxWidth: 150 }} />
      </div>
      {SaleChannel.Dashboards.map((element, index) => {
        return (
          <Link to={`/dashboard/${index}`} className={styles.sideMenu} key={index}>
            <ListItem button className={clsx((pathCurrent === "/dashboard/" + index) && styles.btnMenuHover)}>
              <ListItemIcon>
                <Dashboard style={(pathCurrent !== "/dashboard/" + index) ? { color: theme.palette.primary.main } : { color: '#fff' }} />
              </ListItemIcon>
              <ListItemText primary={element.Name} />
            </ListItem>
          </Link>
        )
      })
      }
      <ListItem button style={{ position: 'absolute', bottom: 0 }}>
        <ListItemIcon>
          <ExitToAppIcon style={{ color: theme.palette.primary.main }} />
        </ListItemIcon>
        <ListItemText primary="Sair" onClick={() => doLogout()} />
      </ListItem>
    </div>
  )
}