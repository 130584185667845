const config = {
  companies: [
    {
      IdSaleChannel:1,
      Name: 'DIRETORIA',
      Dashboards: [
        {
          Name: 'Dashboard - Canais de Vendas',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/74e7d373-8275-4a28-a89c-2f1fecf91f5c',
        },
		{
		  Name: 'Dashboard Wenov',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/1d9df301-89b0-45c9-9b1b-f53181669d44',
        }
      ]
    },
    {
      IdSaleChannel: 5,
      Name: 'REAL PAY',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/aa649c95-62c7-40f1-9226-f20287c0f430',
        },
        {
          Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/65334ac7-9650-4d35-99bf-e1a3138abe58',
        },
        {
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/f06fcff2-a5d7-490c-a22d-cde0ef22f474',
        }
      ]
    },
    {
      IdSaleChannel: 15,
      Name: 'SECURE CARD',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/49047e27-7850-47d4-ac58-32b84f9213d3',
        },
        {
          Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/1d60b60d-a540-4e36-9b6f-e234508bba6d',
        },
        {
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/97c353c2-f5ef-4c6b-87cf-fdeb9e665f54',
        }
      ]
    },
    {
      IdSaleChannel: 20,
      Name: '360 NEW',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/545e6f4f-c0a4-4877-b816-63a0132e44f6',
        },
        {
          Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/5d6ea820-b812-467d-9ec4-d428a8abc42a',
        },
        {
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/73222589-46bc-44d9-a34e-08d4cda82050',
        }
      ]
    },
    {
      IdSaleChannel: 33,
      Name: 'EUROPAY',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/3e88aeca-f7bc-4a15-9c45-a301d0017f5b',
        },
        {
          Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/caa92589-07b2-4776-b1d1-6f79d8472b50',
        },
        {
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/5a6a8669-11fd-45a9-9b28-9ccd74781746',
        }
      ]
    },
	{
      IdSaleChannel: 34,
      Name: 'MaxiPay',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/d28d37ff-3ad8-4ac3-b658-aaa01e68229e',
        },
        {
          Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/66d0c285-cfc8-4652-987d-33e80de1a72b',
        },
        {
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/1d811141-283e-4c35-a7ee-6925487cd381',
        }
      ]
    },
    {
      IdSaleChannel: 36,
      Name: 'REAL BANK',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/40259288-d681-4531-8629-498dbf98788f',
        },
        {
          Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/484815c0-b115-4910-aa2f-283464a7531a',
        },
        {
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/7dfb95ca-8b90-4e73-a01d-450f7b695f2f',
        }
      ]
    },
	{
      IdSaleChannel: 42,
      Name: 'SOLLO PAY',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/983bb399-085f-4e21-8e2e-8247959914b1',
        },
		{
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/908c4efd-694f-4219-a27a-4970615a17b6',
		},
		{
		  Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/d90c5609-2a31-4ab0-b6fb-628dbb014095',
        },
		{
		  Name: 'Consolidação TPV por Cliente',
          Url: 'https://reports.cloudpayments.com.br/public/question/979a2c51-cd7d-4eab-aae2-870aeeeec1d4',
        },
		{
		  Name: 'Clientes por Consultor',
          Url: 'https://reports.cloudpayments.com.br/public/question/fd3ca377-5886-447c-98ba-2e07b7063a30',
        }
      ]
    },
    {
      IdSaleChannel: 41,
      Name: 'KART PAY',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/5eb5b510-c00b-4042-ad8a-75f47afaf11a',
        },
		{
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/434b2d19-a7b0-467e-81f3-178b8481ebdf',
		},
		{
		  Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/1be71eff-c2e3-436b-a34d-32b36a72a2b2',
        },
		{
		  Name: 'Consolidação TPV por Cliente',
          Url: 'https://reports.cloudpayments.com.br/public/question/9295ffda-a382-417b-941b-a860d2b7b231',
        },
		{
		  Name: 'Clientes por Consultor',
          Url: 'https://reports.cloudpayments.com.br/public/question/0651be90-de3b-40ce-a80d-cf86e727f02a',
        }
      ]
    },
    {
      IdSaleChannel: 29,
      Name: 'OrBank',
      Dashboards: [
        {
          Name: 'Dashboard',
          Url: 'https://reports.cloudpayments.com.br/public/dashboard/85078f40-eec1-4821-adbe-c4a42dfdd3a6',
        },
		{
          Name: 'Antecipação',
          Url: 'https://reports.cloudpayments.com.br/public/question/4e326a8b-0010-498d-96c6-8e26b7f377b7',
		},
		{
		  Name: 'Recebíveis',
          Url: 'https://reports.cloudpayments.com.br/public/question/4bc25505-97a0-4f9c-9b72-21675f87daab',
        },
		{
		  Name: 'Consolidação TPV por Cliente',
          Url: 'https://reports.cloudpayments.com.br/public/question/673c9e94-1c35-48b5-994e-0a1c10f447f8',
        },
		{
		  Name: 'Clientes por Consultor',
          Url: 'https://reports.cloudpayments.com.br/public/question/62ffe11e-bbb4-4ed1-b6ab-81559d27921a',
        }
      ]
    },
    {
      IdSaleChannel: 50,
      Name: 'Premium Pay',
      Dashboards: [
            {
              Name: 'Dashboard',
              Url: 'https://reports.cloudpayments.com.br/public/dashboard/a23375e8-661b-43d5-89e7-5884e803d70a',
            },
        {
              Name: 'Antecipação',
              Url: 'https://reports.cloudpayments.com.br/public/question/ce7e3f2e-c42e-44bd-8dd2-334133c98edc',
        },
        {
          Name: 'Recebíveis',
              Url: 'https://reports.cloudpayments.com.br/public/question/0a3ca483-6038-4929-a521-59fcf32da4c6',
            },
        {
          Name: 'Consolidação TPV por Cliente',
              Url: 'https://reports.cloudpayments.com.br/public/question/e1e418a0-0337-49d4-b0e6-e73b2dd2e9aa',
            },
        {
          Name: 'Clientes por Consultor',
              Url: 'https://reports.cloudpayments.com.br/public/question/56bfedcd-4a9d-45e9-8a65-032431e825d9',
            }
          ]
    },
    {
      IdSaleChannel: 46,
      Name: 'Safepay',
      Dashboards: [
          {
            Name: 'Dashboard',
            Url: 'https://reports.cloudpayments.com.br/public/dashboard/da9afc0a-f964-45b8-b69f-12bd096c9b94',
          },
      {
            Name: 'Antecipação',
            Url: 'https://reports.cloudpayments.com.br/public/question/fb9720b6-1d1f-4da2-9a96-7649db31d932',
      },
      {
        Name: 'Recebíveis',
            Url: 'https://reports.cloudpayments.com.br/public/question/0acd85dc-875e-4646-823e-6d77d6dd9fea',
          },
      {
        Name: 'Consolidação TPV por Cliente',
            Url: 'https://reports.cloudpayments.com.br/public/question/7cbe6d8d-aebf-4453-896a-e74a63f050ec',
          },
      {
        Name: 'Clientes por Consultor',
            Url: 'https://reports.cloudpayments.com.br/public/question/797f258e-b7c8-483e-ae47-92280c82e395',
          }
        ]
    }      
  ]
}

export default config;