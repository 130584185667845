import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { verifyLogged } from '../store/actions';

import Main from '../components/layout';

import LoginScreen from '../container/login'

const isLogged = verifyLogged();

export default function Routes(props) {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <BrowserRouter>
      <Switch>
      {
          isLogged ?
            <Main />
            :
            <>
              <Route path={`${publicUrl}/login`} exact component={LoginScreen} />
              <Redirect to={{ pathname: `${publicUrl}/login` }} />
            </>
        }
      </Switch>
    </BrowserRouter>
  )
}